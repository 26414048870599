import urls from '@/utils/urls'
import http from './http'

const config = { baseURL: urls.SUPERVISOR_API }
const bffConfig = { baseURL: urls.BFF_API }

const getItems = (applicationResponseId) => http.get(
  `/applicationResponses/${applicationResponseId}/items`,
  bffConfig,
)

const getExamItem = (examId, itemId) => http.get(`/exams/${examId}/items/${itemId}`)

const answerItem = ({ applicationResponseId }, data) => {
  const {
    itemId, markedOption, questionId, studentOrder,
  } = data

  return http.post(
    `/applicationResponses/${applicationResponseId}/answers`,
    {
      itemId,
      markedOption,
      questionId,
      studentOrder,
    },
    config,
  )
}

const updateAnswer = ({ applicationResponseId, answerId }, data) => {
  const {
    itemId, markedOption, questionId, studentOrder,
  } = data

  return http.put(
    `/applicationResponses/${applicationResponseId}/answers/${answerId}`,
    {
      itemId,
      markedOption,
      questionId,
      studentOrder,
    },
    config,
  )
}

const discardOptions = ({
  applicationResponseId,
  itemId,
  discardedOptions,
}) => http.post(
  `/applicationResponses/${applicationResponseId}/discardOptions`,
  {
    itemId,
    discardedOptions,
  },
  config,
)

const updateDiscardOptions = ({
  applicationResponseId,
  discardOptionId,
  discardedOptions,
}) => http.put(
  `/applicationResponses/${applicationResponseId}/discardOptions/${discardOptionId}`,
  { discardedOptions },
  config,
)

export default {
  getItems,
  getExamItem,
  answerItem,
  discardOptions,
  updateDiscardOptions,
  updateAnswer,
}
