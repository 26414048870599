import { decompressSync, strFromU8 } from 'fflate'
import { getCookie } from '@/utils/cookies'

const crossTokenCookieName = 'auth_cookie'

export function getCrossData() {
  const userCrossData = getCookie(crossTokenCookieName)

  if (!userCrossData) {
    return null
  }

  const base64 = Buffer.from(userCrossData, 'base64')
  const decompressed = decompressSync(base64)
  const data = JSON.parse(strFromU8(decompressed))

  return data
}

export function getCrossAuthToken({ formatted = false } = {}) {
  const userCrossData = getCrossData()

  if (userCrossData) {
    return formatted
      ? `Bearer ${userCrossData.token}`
      : userCrossData.token
  }

  return userCrossData
}

export function getCrossAccountId() {
  const userCrossData = getCrossData()

  if (userCrossData) {
    return userCrossData.accountId
  }

  return userCrossData
}

export const hasCrossAuthToken = Boolean(getCrossAuthToken())

export default {
  getCrossData,
  getCrossAccountId,
  getCrossAuthToken,
  hasCrossAuthToken,
}
