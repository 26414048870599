import { cookieDomain } from './domain'

const A_DAY_IN_SECONDS = 86400

export const setCookie = (name, value, options = {}) => {
  if (typeof document === 'undefined') {
    return
  }

  const mergedOptions = {
    path: '/',
    domain: cookieDomain,
    SameSite: process.env.NODE_ENV === 'production'
      ? 'Strict Secure'
      : 'Strict',
    'max-age': A_DAY_IN_SECONDS,
    ...options,
  }

  if (mergedOptions.expires instanceof Date) {
    mergedOptions.expires = mergedOptions.expires.toUTCString()
  }

  let cookieWithOptions = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  Object
    .keys(mergedOptions)
    .forEach((optionKey) => {
      const optionValue = mergedOptions[optionKey]

      cookieWithOptions += `; ${optionKey}`

      if (optionValue !== true) {
        cookieWithOptions += `=${optionValue}`
      }
    })

  document.cookie = cookieWithOptions
}

export const getCookie = (name) => {
  if (typeof document === 'undefined' || !name) {
    return null
  }

  const cookieName = `${name}=`
  const decodedCookies = decodeURIComponent(document.cookie)
  const cookies = decodedCookies.split(';')

  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i]

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }

  return null
}

export const deleteCookie = (name, options) => {
  setCookie(name, '', {
    ...options,
    expires: -1,
  })
}
