const ptBR = {
  home: {
    header: {
      title: 'Plataforma de Avaliações',
      subtitle: 'Avaliações',
      description: 'Faça simulados, consulte as datas e confira os resultados de todas as suas avaliações',
      tab: {
        sas: 'Avaliações SAS Educação',
      },
    },
  },
}

export default ptBR
