import http from '@/api/http'
import urls from '@/utils/urls'

const config = { baseURL: urls.BFF_API }

export default {
  fetchAssessments: (params) => http.get(
    '/students/personal-assessments', {
      ...config,
      params,
    },
  ),
  fetchLectures: () => http.get('/lectures', config),
  fetchAnswerSheet: (applicationResponseId, examId) => http.get(
    `/students/personal-assessments/applicationResponses/${
      applicationResponseId
    }/exams/${
      examId
    }/answer-sheet`,
    config,
  ),
  fetchQuestionDetails: (examId, questionId) => http.get(
    `/exams/${examId}/items/${questionId}`,
  ),
  fetchQuestionStats: (examId, questionId) => http.get(
    `/students/personal-assessments/exams/${examId}/questions/${questionId}/stats`,
    config,
  ),
  fetchPlacementHistogram: (examId) => http.get(
    `/students/personal-assessments/exams/${examId}/classicHistogram`,
    config,
  ),
}
