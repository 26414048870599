import urls from '@/utils/urls'
import http from './http'

const config = { baseURL: urls.ASSIGNMENTS_API }

export default {
  getInfo: (assessmentId) => http.get('syllabus-info', {
    ...config,
    params: { assessmentId },
  }),
}
