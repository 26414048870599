import Vue from 'vue'
import Toasted from 'vue-toasted'
import feather from 'feather-icons'

const featherIcon = (type) => feather.icons[type].toSvg()

const options = {
  theme: 'primary',
  position: 'bottom-left',
  duration: 5000,
}

Vue.use(Toasted, options)

Vue.toasted.register(
  'error',
  (payload) => {
    const icon = payload.customIcon
      ? featherIcon(payload.customIcon)
      : featherIcon('alert-triangle')

    if (payload.errorCode) {
      return `
        ${icon}
        <div>
          <p>${payload.message}</p>
          <small>Erro ${payload.errorCode}</small>
        </div>
      `
    }

    return `${icon} <p>${payload.message}</p>`
  },
  {
    type: 'error',
  },
)

Vue.toasted.register('success', (payload) => {
  const icon = payload.customIcon
    ? featherIcon(payload.customIcon)
    : featherIcon('check')

  return `${icon} <p>${payload.message}</p>`
})

Vue.toasted.register('info', (payload) => {
  const icon = payload.customIcon
    ? featherIcon(payload.customIcon)
    : featherIcon(payload.icon || 'check')

  return `${icon} <p>${payload.message}</p>`
})
