import urls from '@/utils/urls'
import http from './http'

const config = { baseURL: urls.SUPERVISOR_API }
const bffConfig = { baseURL: urls.BFF_API }

const getAssessmentYears = () => http.get('/student/assessments/years', bffConfig)

const getApplications = (yearFilter) => http.get(`/student/assessments?year=${yearFilter}`, bffConfig)

const getApplication = (applicationId) => http.get(`/students/applications/${applicationId}`, config)

const getApplicationResponse = ({ applicationId, examId }) => http.get(
  `/applications/${applicationId}/exams/${examId}/applicationResponses`,
  config,
)

export default {
  getAssessmentYears,
  getApplications,
  getApplication,
  getApplicationResponse,
}
