import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  assessmentYears: [],
  applications: [],
  application: {
    id: 0,
    appointments: [],
    assessmentId: 0,
    completed: false,
    completedDate: null,
  },
  cachedApplication: {},
  applicationResponse: {},
}

export default {
  state,
  getters,
  mutations,
  actions,
}
