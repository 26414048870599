import * as types from '../mutation-types'

export default {
  [types.TOGGLE_QUESTION_DRAWER](state, questionDrawerVisible) {
    state.questionDrawerVisible = questionDrawerVisible
    state.selectedQuestion = {}
  },

  [types.SET_PAGED_QUESTIONS](state, pagedQuestions) {
    state.pagedQuestions = pagedQuestions
  },

  [types.SET_SELECTED_QUESTION](state, selectedQuestion) {
    state.selectedQuestion = selectedQuestion
  },

  [types.SET_CURRRENT_QUESTIONS](state, currentQuestion) {
    state.pagedQuestions.currentQuestion = currentQuestion
  },

}
