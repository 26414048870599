import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  partialResults: [],
  cachedScoreResult: {},
  cachedScoreResultByAssessment: {},
  cachedAreasScoreResult: {},
  cachedHitsResult: {},
  cachedPartialResultHits: {},
  cachedPerformanceCategories: {},
  cachedGlobalPerformance: {},
  cachedPartialItemsPerformance: {},
  cachedItemsPerformance: {},
  cachedGeneralRankings: {},
  cachedGeneralRankingsByAssessment: {},
  cachedParticipation: {},
  cachedParticipationByAssessment: {},
  cachedCategoriesRanking: {},
  cachedHistogram: {},
  cachedHistogramByAssessment: {},
  cachedCategoriesHistogram: {},
  cachedAveragesPerformance: {},
  averagesPerformance: [],
  cachedHitsQuestions: {},
  loadingOlympicSummary: false,
  olympicSummaryExams: null,
}

export default {
  state,
  getters,
  mutations,
  actions,
}
