import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  exams: [],
  exam: {
    applicationId: 0,
    id: 0,
    name: '',
    gradeId: '',
    itemsCount: 0,
    status: '',
  },
  examStarted: false,
}

export default {
  state,
  getters,
  mutations,
  actions,
}
