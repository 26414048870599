export const SET_USER = 'SET_USER'
export const CACHE_USER = 'CACHE_USER'
export const CLEAR_USER = 'CLEAR_USER'

export const SET_ONBOARDING = 'SET_ONBOARDING'

export const SHOW_MAIN_LOADING = 'SHOW_MAIN_LOADING'
export const HIDE_MAIN_LOADING = 'HIDE_MAIN_LOADING'

export const SET_ASSESSMENT_YEARS = 'SET_ASSESSMENT_YEARS'
export const SET_APPLICATIONS = 'SET_APPLICATIONS'
export const SET_APPLICATION = 'SET_APPLICATION'
export const CACHE_APPLICATION = 'CACHE_APPLICATION'
export const SET_APPLICATION_RESPONSE = 'SET_APPLICATION_RESPONSE'

export const SET_ASSESSMENT_TYPE = 'SET_ASSESSMENT_TYPE'

export const SET_ASSESSMENTS = 'SET_ASSESSMENTS'
export const SET_ASSESSMENT = 'SET_ASSESSMENT'
export const CACHE_ASSESSMENT = 'CACHE_ASSESSMENT'

export const SET_EXAMS = 'SET_EXAMS'
export const SET_EXAM = 'SET_EXAM'
export const SET_EXAM_NAME = 'SET_EXAM_NAME'
export const CLEAR_EXAMS = 'CLEAR_EXAMS'
export const CLEAR_EXAM = 'CLEAR_EXAM'

export const SET_ITEM = 'SET_ITEM'
export const CACHE_EXAM_ITEM = 'CACHE_EXAM_ITEM'
export const CACHE_ITEM_VIDEO = 'CACHE_ITEM_VIDEO'
export const SET_ITEMS = 'SET_ITEMS'
export const CLEAR_ITEMS = 'CLEAR_ITEMS'
export const SET_ANSWER = 'SET_ANSWER'
export const ENABLE_ANSWER_STATUS = 'ENABLE_ANSWER_STATUS'
export const DISABLE_ANSWER_STATUS = 'DISABLE_ANSWER_STATUS'
export const SET_DISCARD_OPTIONS = 'SET_DISCARD_OPTIONS'

export const SET_PARTIAL_RESULTS = 'SET_PARTIAL_RESULTS'
export const SET_SCORE_RESULT = 'SET_SCORE_RESULT'
export const CACHE_SCORE_RESULT = 'CACHE_SCORE_RESULT'
export const CACHE_SCORE_RESULT_BY_ASSESSMENT = 'CACHE_SCORE_RESULT_BY_ASSESSMENT'
export const CACHE_AREAS_SCORE_RESULT = 'CACHE_AREAS_SCORE_RESULT'
export const CACHE_HITS_RESULT = 'CACHE_HITS_RESULT'
export const CACHE_PARTIAL_RESULT_HITS = 'CACHE_PARTIAL_RESULT_HITS'
export const CACHE_PERFORMANCE_CATEGORIES = 'CACHE_PERFORMANCE_CATEGORIES'
export const CACHE_CATEGORIES_GLOBAL_PERFORMANCE = 'CACHE_CATEGORIES_GLOBAL_PERFORMANCE'
export const CACHE_PARTIAL_ITEMS_PERFORMANCE = 'CACHE_PARTIAL_ITEMS_PERFORMANCE'
export const CACHE_PERFORMANCE_ITEMS = 'CACHE_PERFORMANCE_ITEMS'
export const CACHE_GENERAL_RANKINGS = 'CACHE_GENERAL_RANKINGS'
export const CACHE_GENERAL_RANKINGS_BY_ASSESSMENT = 'CACHE_GENERAL_RANKINGS_BY_ASSESSMENT'
export const CACHE_PARTICIPATION = 'CACHE_PARTICIPATION'
export const CACHE_PARTICIPATION_BY_ASSESSMENT = 'CACHE_PARTICIPATION_BY_ASSESSMENT'
export const CACHE_CATEGORIES_RANKING = 'CACHE_CATEGORIES_RANKING'
export const CACHE_HISTOGRAM = 'CACHE_HISTOGRAM'
export const CACHE_HISTOGRAM_BY_ASSESSMENT = 'CACHE_HISTOGRAM_BY_ASSESSMENT'
export const CACHE_CATEGORIES_HISTOGRAM = 'CACHE_CATEGORIES_HISTOGRAM'

export const CACHE_CATEGORIES_STUDY_GUIDE = 'CACHE_CATEGORIES_STUDY_GUIDE'
export const CACHE_STUDY_GUIDE_VISIBILITY = 'CACHE_STUDY_GUIDE_VISIBILITY'

export const SET_DARK_MODE = 'SET_DARK_MODE'
export const CACHE_AVERAGES_PERFORMANCE = 'CACHE_AVERAGES_PERFORMANCE'

export const TOGGLE_QUESTION_DRAWER = 'TOGGLE_QUESTION_DRAWER'
export const SET_SELECTED_QUESTION = 'SET_SELECTED_QUESTION'
export const SET_PAGED_QUESTIONS = 'SET_PAGED_QUESTIONS'
export const SET_CURRRENT_QUESTIONS = 'SET_CURRRENT_QUESTIONS'
export const CACHE_HITS_QUESTIONS = 'CACHE_HITS_QUESTIONS'
export const SET_LOADING_OLYMPIC_SUMMARY = 'SET_LOADING_OLYMPIC_SUMMARY'
export const SET_OLYMPIC_SUMMARY_EXAMS = 'SET_OLYMPIC_SUMMARY_EXAMS'

export const SET_SYLLABUS_INFO_DATA = 'SET_SYLLABUS_INFO_DATA'
export const SET_SYLLABUS_INFO_LOADING = 'SET_SYLLABUS_INFO_LOADING'
export const SET_SYLLABUS_INFO_ERROR = 'SET_SYLLABUS_INFO_ERROR'
