class AuthTokenService {
  constructor() {
    this.currentToken = null
  }

  getCurrentToken() {
    return this.currentToken
  }

  setCurrentToken(token) {
    if (token !== this.currentToken) {
      this.currentToken = token
    }
  }
}

const AuthTokenServiceInstance = new AuthTokenService()
export default AuthTokenServiceInstance
