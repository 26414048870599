import urls from '@/utils/urls'
import http from './http'

export default {
  authenticate: (portalToken) => {
    const config = { baseURL: `${urls.AUTH_API}/v1` }

    return http.postWithoutCustomHeader('/auth/', { portalToken }, config)
  },
  getUser: () => {
    const config = { baseURL: `${urls.AUTH_API}/v1` }

    return http.get('/users/me', config)
  },
  getPersonalData: () => {
    const config = { baseURL: `${urls.AUTH_API}/v2` }

    return http.get('/personal-data', config)
  },
  fetchUserHistorical: () => {
    const config = { baseURL: urls.SCHOOL_API }

    return http.get('/accounts/historical', config)
  },
}
