import * as types from '../mutation-types'

export default {
  [types.CACHE_CATEGORIES_STUDY_GUIDE](state, { key, categoriesStudyGuide }) {
    state.cachedCategoriesStudyGuide[key] = categoriesStudyGuide
  },

  [types.CACHE_STUDY_GUIDE_VISIBILITY](state, { key, studyGuideVisibility }) {
    state.cachedStudyGuideVisibility[key] = studyGuideVisibility
  },
}
