import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  onboarding: {
    welcomeActive: true,
    tourActive: false,
    tourCompleted: false,
    helpActive: false,
    helpTourButtonPressed: false,
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
