import actions from './actions'
import mutations from './mutations'

const state = {
  cachedLectures: [],
}

export default {
  state,
  mutations,
  actions,
}
