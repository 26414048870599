import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'

import store from '@/store'
import router from '@/router'

const isEnabled = JSON.parse(process.env.VUE_APP_GTM_ENABLED)

Vue.use(VueGtm, {
  id: process.env.VUE_APP_TRACKING_GTM_ID,
  enabled: isEnabled,
  vueRouter: router,
  loadScript: false,
  debug: process.env.VUE_APP_MODE !== 'production',
})

Vue.prototype.$trackEvent = ({ category, action, label }) => {
  Vue.gtm.trackEvent({
    event: 'custom_event_analytics',
    category,
    action,
    label,
    prospection: store.state.profile.personalData?.schoolProspection,
  })
}

Vue.prototype.$track = {
  category: {
    syllabus: 'ExerciciosPersonalizados',
    oldSyllabus: 'PlanoDeEstudos(Antigo)',
    assessmentsInsightsTour: '[Aluno][EP]ModalConvidativo',
  },
  action: {
    syllabus: {
      resultsClick: 'ClickResultadoExercíciosPersonalizados',
      studentClickedOnBanner: 'AlunoClicouNoBanner',
      studentClickedOnDownload: 'AlunoClicouNoDownload',
    },
    assessmentsInsightsTour: {
      dismiss: 'BotãoFechar',
      goToSyllabus: 'IrParaExercíciosPersonalizados',
    },
  },
}
