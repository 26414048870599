import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import PageError from '@/views/PageError'
import homeRoutes from '@/Home/routes'

const Assessment = () => import('@/views/Assessment')
const AssessmentExams = () => import('@/views/AssessmentExams')
const AssessmentResults = () => import('@/views/AssessmentResults')

const Exam = () => import('@/views/Exam')
const ExamItem = () => import('@/views/ExamItem')

const ExamResult = () => import('@/views/ExamResult')
const ExamResultPartial = () => import('@/views/ExamResultPartial')
const ExamResultGeneral = () => import('@/views/ExamResultGeneral')
const ExamResultPerformance = () => import('@/views/ExamResultPerformance')
const ExamResultItems = () => import('@/views/ExamResultItems')

const EssayInstructions = () => import('@/views/EssayInstructions')

const AssessmentReport = () => import('@/PersonalAssessments/views/AssessmentReport')

Vue.use(Router)

const checkAccessToResultView = async ({ to }) => {
  const { assessmentId, applicationId } = to.params
  const [assessment, application] = await Promise.all([
    store.dispatch('getAssessment', assessmentId),
    store.dispatch('getApplication', applicationId),
  ])
  const { status } = application
  const isRankedAssessment = assessment.typeSlug !== 'diagnostica'

  switch (to.name) {
  case 'examResultPartial':
    return isRankedAssessment && status === 'PROCESSING_RESULT'
  case 'examResultGeneral':
  case 'examResultEssay':
  case 'examResultItems':
    return status === 'DONE'
  case 'examResultPerformance':
    return isRankedAssessment && status === 'DONE'
  default:
    return false
  }
}

const beforeEnterResultView = async ({ next, to }) => {
  if (await checkAccessToResultView({
    next,
    to,
  })) {
    next()
  } else {
    next('/home')
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'assessments' },
    },

    homeRoutes,

    {
      path: '/avaliacoes/:assessmentId/aplicacoes/:applicationId',
      component: Assessment,
      props: true,
      children: [
        {
          path: 'provas',
          name: 'assessmentExams',
          component: AssessmentExams,
          props: true,
          meta: {
            topBarVisible: true,
          },
        },

        {
          path: 'resultados',
          name: 'assessmentResults',
          component: AssessmentResults,
          props: true,
          meta: {
            topBarVisible: true,
          },
        },
      ],
    },

    {
      path:
        '/avaliacoes/:assessmentId/aplicacoes/:applicationId/provas/:examId',
      component: Exam,
      props: true,
      children: [
        {
          path: 'questoes/:itemOrder?',
          name: 'exam',
          component: ExamItem,
          props: true,
          meta: {
            topBarVisible: false,
          },
        },
      ],
    },

    {
      path:
        '/avaliacoes/:assessmentId/aplicacoes/:applicationId/provas/:examId/resultado',
      name: 'resultado',
      component: ExamResult,
      props: true,
      children: [
        {
          path: 'parcial',
          name: 'examResultPartial',
          component: ExamResultPartial,
          props: true,
          meta: {
            topBarVisible: true,
            tabsVisible: false,
          },
          beforeEnter: (to, from, next) => beforeEnterResultView({
            to,
            next,
          }),
        },

        {
          path: 'geral',
          name: 'examResultGeneral',
          component: ExamResultGeneral,
          props: true,
          meta: {
            topBarVisible: true,
            tabsVisible: true,
          },
          beforeEnter: (to, from, next) => beforeEnterResultView({
            to,
            next,
          }),
        },

        {
          path: 'redacao',
          name: 'examResultEssay',
          component: EssayInstructions,
          props: true,
          meta: {
            topBarVisible: true,
            tabsVisible: true,
          },
          beforeEnter: (to, from, next) => beforeEnterResultView({
            to,
            next,
          }),
        },

        {
          path: 'desempenho',
          name: 'examResultPerformance',
          component: ExamResultPerformance,
          props: true,
          meta: {
            topBarVisible: true,
            tabsVisible: true,
          },
          beforeEnter: (to, from, next) => beforeEnterResultView({
            to,
            next,
          }),
        },

        {
          path: 'questoes',
          name: 'examResultItems',
          component: ExamResultItems,
          props: true,
          meta: {
            topBarVisible: true,
            tabsVisible: true,
          },
          beforeEnter: (to, from, next) => beforeEnterResultView({
            to,
            next,
          }),
        },
      ],
    },

    {
      path: '/avaliacoes/:assessmentId/aplicacoes/:applicationId/provas/:examId/relatorio-final',
      name: 'assessment-report',
      component: AssessmentReport,
      meta: {
        topBarVisible: true,
      },
    },
    {
      path: '*',
      name: 'NotFound',
      component: PageError,
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return {
      x: 0,
      y: 0,
    }
  },
})

export default router
