import * as types from '../mutation-types'

export default {
  [types.SET_SYLLABUS_INFO_ERROR](state, error) {
    state.error = error
  },

  [types.SET_SYLLABUS_INFO_DATA](state, data) {
    state.data = data
  },

  [types.SET_SYLLABUS_INFO_LOADING](state, value) {
    state.isLoading = value
  },
}
