<template>
  <transition
    mode="out-in"
    name="modal"
  >
    <div
      class="modal__container"
      @keyup.esc="$emit('close')"
    >
      <div
        class="modal"
        :style="{ padding }"
      >
        <SasButton
          v-if="withCloseButton"
          class="modal__close"
          theme="float"
          @click.native="$emit('close')"
        >
          <SasIcon type="x" />
        </SasButton>

        <div
          v-if="$slots.header"
          class="modal__title"
        >
          <slot name="header" />
        </div>

        <div
          v-if="$slots.body"
          class="modal__body"
        >
          <slot name="body" />
        </div>

        <div
          v-if="$slots.footer"
          class="modal__footer"
          :style="{ margin: footerMargin }"
        >
          <slot name="footer" />
        </div>
      </div>

      <div
        class="modal__overlay"
        @click="$emit('close')"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SasModal',
  props: {
    padding: {
      type: String,
      default: '24px',
    },
    withCloseButton: {
      type: Boolean,
      default: true,
    },
    footerMargin: {
      type: String,
      default: '40px 0 0 0',
    },
  },
  mounted() {
    document.body.classList.add('lock-scroll')
  },
  destroyed() {
    document.body.classList.remove('lock-scroll')
  },
}
</script>

<style lang="sass">

.modal
  background: #ffffff
  border-radius: 12px
  min-width: 500px
  max-height: 100%
  backface-visibility: hidden
  overflow-x: auto
  overflow-y: hidden
  transition: all .3s ease
  position: relative
  z-index: 3

  +mq_modal
    min-width: auto
    width: 100vw
    height: 100vh
    border-radius: 0
    padding: $size-m
    overflow-y: scroll
    scroll-behavior: smooth

  +mq-xs
    padding: 16px

  &__container
    backface-visibility: hidden
    position: fixed
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    z-index: 9999
    display: flex
    align-items: center
    justify-content: center

  &__title
    margin-bottom: $size-m
    color: $color-ink

    +mq-s
      margin-top: $size-m

    +mq-xs
      margin-top: $size-l

  &__footer
    display: flex
    background: #ffffff

    .btn ~ .btn
      margin-left: $size-s

    +mq_modal
      position: fixed
      bottom: 0
      width: 100%
      left: 0
      box-sizing: border-box
      box-shadow: 0 -1px 12px rgba(0,0,0,.1)
      padding: 8px 16px
      z-index: 100

  &__options
    display: flex
    align-items: center

    &__item

      & ~ &
        margin-left: $size-m

  &__overlay
    display: flex
    top: 0
    left: 0
    bottom: 0
    right: 0
    position: fixed
    align-items: center
    backface-visibility: hidden
    justify-content: center
    background: rgba(0,0,0,.5)
    transition: opacity .4s ease

  &__close
    position: absolute
    right: 8px
    top: 8px
    opacity: .67

</style>
