import urls from '@/utils/urls'
import http from './http'

const supervisorConfig = { baseURL: urls.SUPERVISOR_API }
const studentBffConfig = { baseURL: urls.BFF_API }

const getExams = (assessmentId) => http.get(`assessments/${assessmentId}/exams`)

const getExam = ({ assessmentId, examId }) => http.get(`assessments/${assessmentId}/exams/${examId}`, studentBffConfig)

const getExamByLocation = (location) => http.get(location)

const startExam = ({ applicationId, examId, foreignLanguageCode }) => http.post('applicationResponses', {
  applicationId,
  examId,
  foreignLanguageCode,
}, supervisorConfig)

const endExam = ({ applicationResponseId, finalizedByStudent }) => http.put(
  `applicationResponses/${applicationResponseId}`,
  { finalizedByStudent: finalizedByStudent || false },
  supervisorConfig,
)

const getApplicationResponsesInfos = (applicationResponseId) => http.get(`applicationResponses/${applicationResponseId}`, supervisorConfig)

const getForeignLanguages = () => http.get('foreignLanguages')

export default {
  getExams,
  getExam,
  getApplicationResponsesInfos,
  getExamByLocation,
  startExam,
  endExam,
  getForeignLanguages,
}
