const ptBR = {
  personalAssessment: {
    filters: {
      years: 'Anos',
      lectures: 'Disciplinas',
      all: 'Todas',
    },
    pages: {
      previous: 'Anterior',
      next: 'Próximo',
    },
    assessmentCard: {
      status: {
        scheduled: 'Agendada',
        available: 'Disponível',
        finalized: 'Finalizada',
        deadline: 'Prazo encerrado',
      },
    },
    assessmentsEmptyState: {
      warning: 'Não há avaliações registradas',
      comment: 'Parece que ainda não existem avaliações registradas, contate a coordenação da sua escola.',
    },
    assessmentReport: {
      assessments: 'Avaliações',
      finalReport: 'Relatório final',
      hits: 'Acertos',
      placement: {
        title: 'Seu posicionamento',
        explanation: 'Você está entre <span>{0}% dos alunos</span> que obtiveram nota entre {1} a {2} nesta prova.',
        legend: {
          student: 'Seu posicionamento',
          others: 'Demais alunos',
        },
        range: '{0} a {1}',
      },
      questionsCount: '{0} de {1} questão | {0} de {1} questões',
      tableTitle: 'Questões',
      tableSubtitle: '{0} questão | {0} questões',
      tableFields: {
        index: 'Nº',
        question: 'Questão',
        answer: 'Resposta',
        feedback: 'Gabarito',
      },
      questionReport: {
        title: 'Questões',
        questionsCount: '{count} questão | {count} questões',
        questionNumber: 'Questão {number}',
        question: 'Questão',
        level: 'Dificuldade',
        content: 'Assunto',
        contents: 'Assuntos',
        skill: 'Habilidade BNCC',
        skillContent: 'Objeto de conhecimento',
        answer: 'Resposta',
        hit: 'Acertou',
        missed: 'Errou',
        notAnswered: 'Em branco',
        explanation: 'Resolução',
        hitRateLong: 'Índice de acerto da questão',
        studentHits: '{0} de {1} aluno acertou | {0} de {1} alunos acertaram',
      },
    },
    feedback: {
      fetchLecturesError: 'Ocorreu um erro ao carregar suas disciplinas.',
      fetchAssessmentsError: 'Não foi possível carregar suas avaliações.',
      fetchAnswerSheetError: 'Não foi possível carregar o relatório da avaliação.',
      fetchQuestionDetailsError: 'Não foi possível carregar os detalhes da questão.',
    },
    error: {
      warning: 'Ops, não conseguimos exibir suas avaliações',
      comment: 'Ocorreu um problema ao tentar carregar as avaliações, por favor tente novamente.',
    },
  },
}

export default ptBR
