import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  user: {
    id: 0,
    name: '',
    profiles: [{ name: '' }],
    authorization: '',
  },
  cachedUser: {},
}

export default {
  state,
  getters,
  mutations,
  actions,
}
