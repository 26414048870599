import Vue from 'vue'
import Vuex from 'vuex'

import onboarding from '@/store/onboarding'
import loader from '@/store/loader'
import account from '@/store/account'
import applications from '@/store/applications'
import assessmentType from '@/store/assessmentType'
import assessment from '@/store/assessment'
import exam from '@/store/exam'
import item from '@/store/item'
import result from '@/store/result'
import studyGuide from '@/store/studyGuide'
import configuration from '@/store/configuration'
import question from '@/store/question'
import syllabus from '@/store/syllabus'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    onboarding,
    loader,
    account,
    applications,
    assessmentType,
    assessment,
    exam,
    item,
    result,
    studyGuide,
    configuration,
    question,
    syllabus,
  },
  strict: process.env.NODE_ENV !== 'production',
})
