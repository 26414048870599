import assessmentsApi from '@/api/assessment'
import * as types from '../mutation-types'

export default {
  setAssessment: ({ commit }, assessment) => {
    commit(types.SET_ASSESSMENT, { assessment })
  },

  getAssessments: ({ commit }, applications) => {
    const legacyApplications = applications.filter(
      ({ legacyAccess }) => legacyAccess,
    )
    const pdaApplications = applications.filter(
      ({ legacyAccess }) => !legacyAccess,
    )
    const assessmentsData = [...legacyApplications, ...pdaApplications]
    commit(types.SET_ASSESSMENTS, { assessments: assessmentsData })
  },

  getAssessment: async ({ state, commit, dispatch }, assessmentId) => {
    const key = `${assessmentId}`
    const cachedAssessment = state.cachedAssessment[key]
    if (cachedAssessment) {
      commit(types.SET_ASSESSMENT, { assessment: cachedAssessment })

      return Promise.resolve(cachedAssessment)
    }

    const assessmentResponse = await assessmentsApi.getAssessment(assessmentId)
    const typeResponse = await dispatch(
      'getAssessmentType',
      assessmentResponse.data.typeId,
    )
    const assessment = {
      ...assessmentResponse.data,
      color: typeResponse.data.color,
    }
    commit(types.SET_ASSESSMENT, { assessment })
    commit(types.CACHE_ASSESSMENT, {
      key,
      assessment,
    })

    return assessment
  },
}
