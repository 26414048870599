import itemsApi from '@/api/item'
import * as types from '../mutation-types'

export default {
  setItem: ({ commit }, itemOrder) => {
    commit(types.SET_ITEM, { itemOrder })
  },

  setItems: ({ commit }, items) => {
    commit(types.SET_ITEMS, { items })
  },

  getItem: (context, questionId) => itemsApi.getItem(questionId),

  answerItem: async (
    { state, commit },
    { markedOption, applicationResponseId },
  ) => {
    const {
      id, answer, questionId, studentOrder,
    } = state.item
    const previousAnswer = {
      id: state.item.answer.id,
      markedOption: state.item.answer.markedOption,
    }
    const prefetchAnswer = {
      id: state.item.answer.id,
      markedOption,
    }

    try {
      commit(types.ENABLE_ANSWER_STATUS)
      commit(types.SET_ANSWER, { answer: prefetchAnswer })

      const answerItem = answer.id
        ? itemsApi.updateAnswer
        : itemsApi.answerItem

      const { data } = await answerItem(
        {
          applicationResponseId,
          answerId: answer.id,
        },
        {
          itemId: id,
          markedOption,
          questionId,
          studentOrder,
        },
      )

      const selectedAnswer = {
        id: data.id,
        markedOption: data.markedOption,
      }

      commit(types.SET_ANSWER, { answer: selectedAnswer })
    } catch (error) {
      commit(types.SET_ANSWER, { answer: previousAnswer })
      throw error
    } finally {
      commit(types.DISABLE_ANSWER_STATUS)
    }
  },

  discardOptions: async ({ state, commit },
    { optionOrder, applicationResponseId }) => {
    const discardedOptions = state.item.discardOption
      ? state.item.discardOption.discardedOptions.slice()
      : []

    if (discardedOptions.includes(optionOrder)) {
      discardedOptions.splice(discardedOptions.indexOf(optionOrder), 1)
    } else {
      discardedOptions.push(optionOrder)
    }

    const params = {
      applicationResponseId,
      itemId: state.item.id,
      discardedOptions,
      discardOptionId: state.item.discardOption
        ? state.item.discardOption.id
        : null,
    }
    const { data } = state.item.discardOption
      ? await itemsApi.updateDiscardOptions(params)
      : await itemsApi.discardOptions(params)

    const discardOptions = data ? {
      id: data.id,
      itemId: data.itemId,
      discardedOptions: data.discardedOptions,
    } : null

    commit(types.SET_DISCARD_OPTIONS, discardOptions)
  },

  getItems: ({ commit }, applicationResponseId) => itemsApi
    .getItems(applicationResponseId).then((response) => {
      const items = response.data.map((item) => ({
        ...item,
        answer: item.answer || {
          id: 0,
          markedOption: -1,
        },
      }))

      commit(types.SET_ITEMS, { items })

      return items
    }),

  clearItems: ({ commit }) => {
    commit(types.CLEAR_ITEMS)
  },

  getExamItem: ({ state, commit }, { examId, itemId }) => {
    const key = `${examId}__${itemId}`
    const cachedExamItem = state.cachedExamItem[key]
    if (cachedExamItem) {
      return Promise.resolve(cachedExamItem)
    }

    return itemsApi.getExamItem(examId, itemId).then((response) => {
      const examItem = response.data
      commit(types.CACHE_EXAM_ITEM, {
        key,
        examItem,
      })

      return examItem
    })
  },
}
