import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  assessmentType: {
    id: 0,
    color: '',
    imageUrl: null,
    name: '',
    slug: '',
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
