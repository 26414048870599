<template>
  <div id="app">
    <AppMain>
      <router-view />
    </AppMain>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppMain from '@/components/AppMain'

export default {
  name: 'App',
  components: {
    AppMain,
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    this.addUserToDataLayer()
  },
  methods: {
    ...mapActions(['getUser']),
    async addUserToDataLayer() {
      await this.getUser()
      const {
        id,
        school,
        profiles,
        grades,
      } = this.user

      window.dataLayer.push({
        accountId: id,
        schoolId: school.id,
        profileId: profiles[0].id,
        gradeId: grades.map((grade) => grade.id),
        prospection: this.$store.state.profile.personalData?.schoolProspection,
      })
    },
  },
}
</script>

<style lang="sass">
@import '~@/styles/main'

body
  background: $color-ice
  overflow-x: hidden

  &.lock-scroll
    overflow: hidden

  #app
    padding-top: $size-xxl
</style>
