import * as types from '../mutation-types'

export default {
  [types.SET_PARTIAL_RESULTS](state, { partialResults }) {
    state.partialResults = partialResults
  },

  [types.SET_SCORE_RESULT](state, { scoreResult }) {
    state.scoreResult = scoreResult
  },

  [types.CACHE_SCORE_RESULT](state, { key, scoreResult }) {
    state.cachedScoreResult[key] = scoreResult
  },

  [types.CACHE_SCORE_RESULT_BY_ASSESSMENT](state, { key, scoreResult }) {
    state.cachedScoreResultByAssessment[key] = scoreResult
  },

  [types.CACHE_AREAS_SCORE_RESULT](state, { key, areasScoreResult }) {
    state.cachedAreasScoreResult[key] = areasScoreResult
  },

  [types.CACHE_HITS_RESULT](state, { key, resultHits }) {
    state.cachedHitsResult[key] = resultHits
  },

  [types.CACHE_PARTIAL_RESULT_HITS](state, { key, resultHits }) {
    state.cachedPartialResultHits[key] = resultHits
  },

  [types.CACHE_PERFORMANCE_CATEGORIES](state, { key, performanceCategories }) {
    state.cachedPerformanceCategories[key] = performanceCategories
  },

  [types.CACHE_CATEGORIES_GLOBAL_PERFORMANCE](
    state,
    { key, globalPerformance },
  ) {
    state.cachedGlobalPerformance[key] = globalPerformance
  },

  [types.CACHE_PARTIAL_ITEMS_PERFORMANCE](state, { key, itemsPerformance }) {
    state.cachedPartialItemsPerformance[key] = itemsPerformance
  },

  [types.CACHE_PERFORMANCE_ITEMS](state, { key, itemsPerformance }) {
    state.cachedItemsPerformance[key] = itemsPerformance
  },

  [types.CACHE_GENERAL_RANKINGS](state, { key, generalRankings }) {
    state.cachedGeneralRankings[key] = generalRankings
  },

  [types.CACHE_GENERAL_RANKINGS_BY_ASSESSMENT](state, {
    key, generalRankings,
  }) {
    state.cachedGeneralRankingsByAssessment[key] = generalRankings
  },

  [types.CACHE_PARTICIPATION](state, { key, participation }) {
    state.cachedParticipation[key] = participation
  },

  [types.CACHE_PARTICIPATION_BY_ASSESSMENT](state, { key, participation }) {
    state.cachedParticipationByAssessment[key] = participation
  },

  [types.CACHE_CATEGORIES_RANKING](state, { key, categoriesRanking }) {
    state.cachedCategoriesRanking[key] = categoriesRanking
  },

  [types.CACHE_HISTOGRAM](state, { key, histogram }) {
    state.cachedHistogram[key] = histogram
  },

  [types.CACHE_HISTOGRAM_BY_ASSESSMENT](state, { key, histogram }) {
    state.cachedHistogramByAssessment[key] = histogram
  },

  [types.CACHE_CATEGORIES_HISTOGRAM](state, { key, categoriesHistogram }) {
    state.cachedCategoriesHistogram[key] = categoriesHistogram
  },

  [types.CACHE_AVERAGES_PERFORMANCE](state, { key, averagesPerformance }) {
    state.cachedAveragesPerformance[key] = averagesPerformance
    state.averagesPerformance = averagesPerformance
  },

  [types.CACHE_HITS_QUESTIONS](state, { key, hitsQuestions }) {
    state.cachedHitsQuestions[key] = hitsQuestions
  },

  [types.SET_LOADING_OLYMPIC_SUMMARY](state, data) {
    state.loadingOlympicSummary = data
  },

  [types.SET_OLYMPIC_SUMMARY_EXAMS](state, data) {
    state.olympicSummaryExams = data
  },
}
