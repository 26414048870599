import accountApi from '@/api/account'
import * as types from '../mutation-types'
import supportLegacy from '@/crossIntegration/SupportLegacyPeriodService'
import AuthTokenServiceInstance from '@/crossIntegration/AuthTokenService'
import getInitialToken from '@/crossIntegration/utils/getInitialToken'
import getIsFromCross from '@/crossIntegration/utils/getIsFromCross'

export default {
  getUser: async ({ state, commit }) => {
    let currentToken = AuthTokenServiceInstance.getCurrentToken()

    commit(types.SHOW_MAIN_LOADING)

    if (!currentToken) {
      currentToken = await getInitialToken()
    }

    await supportLegacy.loadSupportLegacyFeatureToggle()

    AuthTokenServiceInstance.setCurrentToken(currentToken)

    const getUserData = (data) => ({
      ...data,
      isFromCross: getIsFromCross(),
    })

    if (state.cachedUser[currentToken]) {
      const user = getUserData(state.cachedUser[currentToken])

      commit(types.SET_USER, { user })
      commit(types.HIDE_MAIN_LOADING)

      return Promise.resolve(state.cachedUser[currentToken])
    }

    const response = await accountApi.getUser()
    const user = getUserData(response.data)

    commit(types.CACHE_USER, {
      key: currentToken,
      user,
    })
    commit(types.SET_USER, { user })
    commit(types.HIDE_MAIN_LOADING)

    return Promise.resolve(user)
  },
}
