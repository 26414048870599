import studyGuideApi from '@/api/studyGuide'

import * as types from '../mutation-types'

const setStudyGuideVisibility = (commit, { key, visibility }) => {
  commit(types.CACHE_STUDY_GUIDE_VISIBILITY, {
    key,
    studyGuideVisibility: visibility,
  })
}
export default {
  getStudyGuide: async (_, { assessmentId, examId }) => {
    const { getStudyGuide, getEnemStudyGuide } = studyGuideApi
    const getAssessmentStudyGuide = assessmentId
      ? getEnemStudyGuide
      : getStudyGuide

    const param = assessmentId || examId
    const { data: { content } } = await getAssessmentStudyGuide(param)
    const categoriesStudyGuide = content

    return categoriesStudyGuide
  },

  existsStudyGuide: async ({ state, commit }, { applicationId, examId }) => {
    const key = `${applicationId}__${examId}`
    const cachedStudyGuideVisibility = state.cachedStudyGuideVisibility[key]

    if (cachedStudyGuideVisibility) {
      return cachedStudyGuideVisibility
    }

    try {
      await studyGuideApi.existsStudyGuide(examId)

      setStudyGuideVisibility(commit, {
        key,
        visibility: true,
      })

      return true
    } catch (error) {
      const NOT_FOUND = 404

      if (error.response && error.response.status !== NOT_FOUND) {
        return false
      }

      setStudyGuideVisibility(commit, {
        key,
        visibility: true,
      })

      return false
    }
  },

  downloadStudyGuideQuestions: (context, {
    title,
    guids,
    examType,
  }) => studyGuideApi.downloadStudyGuideQuestions({
    title,
    guids,
    examType,
  }),
}
