import * as types from '../mutation-types'

export default {
  toggleQuestionDrawer: ({ commit }, questionDrawerVisible) => {
    commit(types.TOGGLE_QUESTION_DRAWER, questionDrawerVisible)
  },

  setPagedQuestions: ({ commit }, pagedQuestions = {}) => {
    commit(types.SET_PAGED_QUESTIONS, pagedQuestions)
  },

  setSelectedQuestion: ({ commit }, selectedQuestion = {}) => {
    commit(types.SET_SELECTED_QUESTION, selectedQuestion)
  },

  setCurrentQuestion: ({ commit }, currentQuestions) => {
    commit(types.SET_CURRRENT_QUESTIONS, currentQuestions)
  },

}
