import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  assessments: [],
  assessment: {
    id: '',
    name: '',
    timeWindowEnd: '',
    timeWindowStart: '',
    typeId: 0,
    color: '',
    applicationId: 0,
    slugType: '',
    gradeId: 0,
    isGradedByIrt: false,
  },
  cachedAssessment: {},
}

export default {
  state,
  getters,
  mutations,
  actions,
}
