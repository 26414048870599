const production = {
  PORTAL_API: 'https://portal-api.sasdigital.com.br',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  CROSS_PORTAL_URL: 'https://app.portalsaseducacao.com.br',
  LOTUS_API: 'https://assessment-manager-service.sasdigital.com.br/',
  SUPERVISOR_API: 'https://assessment-supervisor.sasdigital.com.br/',
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  SCHOOL_API: 'https://schoolapi.sasdigital.com.br/api/v1',
  RESULTS_API: 'https://assessment-results.sasdigital.com.br/',
  ASSIGNMENTS_API: 'https://assignment-backend.sasdigital.com.br',
  BFF_API: 'https://student-assessment-backend.sasdigital.com.br/',
  QUESTIONS_DATABASE_API: 'https://api.sasdigital.com.br/assessment-backend',
  ASSIGNMENT_BFF_API: 'https://assignment-bff.sasdigital.com.br',
  SYLLABUS_URL: 'https://assignments.portalsaseducacao.com.br/exercicios-personalizados',
  ASSESSMENTS_INSIGHTS_URL: 'https://assessments-insights.portalsaseducacao.com.br/exercicios-personalizados',
  RANKED_STUDY_GUIDE_URI: 'https://plano-de-estudos.portalsaseducacao.com.br/Alunos',
  EUREKA_BASE_API: 'https://api.sasdigital.com.br/eureka-frontend-bff',
}

const staging = {
  PORTAL_API: 'https://portal-api.staging.sasdigital.com.br',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  CROSS_PORTAL_URL: 'https://app-stg.portalsaseducacao.com.br',
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  SCHOOL_API: 'https://schoolapi.staging.sasdigital.com.br/api/v1',
  BFF_API: 'https://student-assessment-backend.staging.sasdigital.com.br',
  LOTUS_API: 'https://assessment-manager-service.staging.sasdigital.com.br',
  SUPERVISOR_API: 'https://assessment-supervisor.staging.sasdigital.com.br',
  RESULTS_API: 'https://assessment-results.staging.sasdigital.com.br',
  ASSIGNMENTS_API: 'https://assignment-backend.staging.sasdigital.com.br',
  QUESTIONS_DATABASE_API: 'https://api.staging.sasdigital.com.br/assessment-backend',
  ASSIGNMENT_BFF_API: 'https://assignment-bff.staging.sasdigital.com.br',
  SYLLABUS_URL: 'https://assignments.staging.portalsaseducacao.com.br/exercicios-personalizados',
  ASSESSMENTS_INSIGHTS_URL: 'https://assessments-insights.staging.portalsaseducacao.com.br/exercicios-personalizados',
  RANKED_STUDY_GUIDE_URI: 'https://plano-de-estudos.staging.portalsaseducacao.com.br/Alunos',
  EUREKA_BASE_API: 'https://api.staging.sasdigital.com.br/eureka-frontend-bff',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
  CROSS_PORTAL_URL: 'http://localhost:3002',
  EUREKA_BASE_API: 'http://localhost:9011/eureka-frontend-bff',
  BFF_API: 'http://localhost:9011/student-assessment-backend',
  AUTH_API: 'http://localhost:9011/auth-server',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.VUE_APP_MODE]

export default urls
