import axios from 'axios'
import { addInjectedAuthorizationHeader, addXAccountIdHeader } from '@/crossIntegration/utils/apiConfig'
import urls from '@/utils/urls'
import AuthTokenServiceInstance from '@/crossIntegration/AuthTokenService'
import httpCodes from '@/enums/httpCodes'
import {
  updateTokens,
  hasRefreshToken,
} from '@/utils/auth'

import { handleRedirection, logout } from '@/crossIntegration/utils/auth'
import { hasCrossAuthToken } from '@/crossIntegration/utils/userInfo'

const crossIntegrationApi = axios.create({
  baseURL: urls.EUREKA_BASE_API,
})

const handleRequestInterceptorError = async (error) => {
  if (error.response) {
    const { status } = error.response

    if (status === httpCodes.unauthorized) {
      if (hasRefreshToken()) {
        try {
          await updateTokens()

          return axios(addInjectedAuthorizationHeader(error.config, AuthTokenServiceInstance))
        } catch (refreshTokenError) {
          logout({ isFromCross: hasCrossAuthToken })
        }
      }
    }

    const timeout = 1000
    setTimeout(() => {
      handleRedirection({
        status,
        isFromCross: hasCrossAuthToken,
      })
    }, timeout)
  }

  return Promise.reject(error)
}

crossIntegrationApi.interceptors.request.use((config) => addInjectedAuthorizationHeader(
  config,
  AuthTokenServiceInstance,
))
crossIntegrationApi.interceptors.request.use(addXAccountIdHeader)
crossIntegrationApi.interceptors.response.use((response) => response, handleRequestInterceptorError)

export default {
  crossIntegrationApi,
  fetchIsSupportLegacyEndDate: () => crossIntegrationApi.get('/toggle/is-support-legacy-end-date'),
}
