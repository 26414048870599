import Vue from 'vue'
import Viewer from 'v-viewer'
import VueTruncate from 'vue-truncate-filter'
import VTooltip from 'v-tooltip'
import alfabeto from '@sas-te/alfabeto-vue'
import mainMenu from '@sas-te/main-menu-vue'
import moment from 'moment'
import VueCarousel from 'vue-carousel'
import VueTour from 'vue-tour'

import 'viewerjs/dist/viewer.css'
import 'vue-tour/dist/vue-tour.css'
import '@sas-te/alfabeto-vue/dist/alfabeto.css'

import App from './App.vue'

import i18n from './i18n'
import store from './store'
import router from './router'

import './registerGlobalComponents'
import './toasted'
import './gtm'
import './plugins/vue-ref'

const SMALL_SCREEN_BREAKPOINT = 768

moment.locale('pt-br')

Vue.use(alfabeto)
Vue.use(mainMenu, {
  store,
  env: process.env.VUE_APP_MODE,
})
Vue.use(VTooltip)
Vue.use(Viewer)
Vue.use(VueTruncate)
Vue.use(VueCarousel)
Vue.use(VueTour)

VTooltip.enabled = window.innerWidth > SMALL_SCREEN_BREAKPOINT

Vue.prototype.$moment = moment
Vue.config.productionTip = false

new Vue({
  name: 'Root',
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
