import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  items: [],
  item: {
    id: 0,
    questionId: '',
    order: 0,
    statement: '',
    alternatives: [],
    answer: {
      id: 0,
      markedOption: -1,
    },
    discardOption: {},
  },
  isAnswering: false,
  cachedExamItem: {},
  cachedItemVideo: {},
}

export default {
  state,
  getters,
  mutations,
  actions,
}
