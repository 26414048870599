<template>
  <div class="help-badge">
    <SasButton
      class="help-badge__toggle"
      size="big"
      @click="$emit('open')"
    >
      <Icon
        slot="left"
        stroke="white"
        type="help-circle"
      />
      <p v-if="$mq !== 'small'">
        Ajuda
      </p>
    </SasButton>
  </div>
</template>

<script>
export default {
  name: 'HelpBadge',
}
</script>

<style lang="sass">

.help-badge
  position: fixed
  bottom: 16px
  right: 16px
  box-shadow: 0 1px 16px rgba(0,0,0,.35)
  border-radius: 100px !important

  &__toggle
    border-radius: 100px !important
    background: $color-ink-light !important

    +mq-s
      padding: 0 12px !important

      .btn__icon--left
        margin-right: 0 !important
        margin-left: 0 !important

    &:hover
      transform: scale(1.03) !important

    &:focus, &:active
      background: shade-color($color-ink-light, 10%) !important
      box-shadow: none !important

</style>
