import store from '@/store'
import personalAssessmentStore from './store'

const beforeEnter = (to, from, next) => {
  if (!store.state.personalAssessment) {
    store.registerModule('personalAssessment', personalAssessmentStore)
  }
  next()
}

export default {
  path: 'escola',
  name: 'personal-assessments',
  component: () => import('./views/PersonalAssessments'),
  meta: {
    topBarVisible: true,
  },
  beforeEnter,
}
