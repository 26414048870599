import syllabusApi from '@/api/syllabus'

import * as types from '../mutation-types'

export default {
  getSyllabusInfo: async ({ commit }, assessmentId) => {
    commit(types.SET_SYLLABUS_INFO_ERROR, false)
    commit(types.SET_SYLLABUS_INFO_LOADING, true)

    try {
      const { data } = await syllabusApi.getInfo(assessmentId)

      commit(types.SET_SYLLABUS_INFO_LOADING, false)
      commit(types.SET_SYLLABUS_INFO_DATA, data)
    } catch {
      commit(types.SET_SYLLABUS_INFO_ERROR, true)
    }
  },
}
