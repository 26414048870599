import personalAssessmentsRoutes from '@/PersonalAssessments/routes'

export default {
  path: '/avaliacoes',
  component: () => import('./views/Home'),
  children: [
    {
      path: '',
      name: 'assessments',
      component: () => import('@/views/Assessments'),
      meta: {
        topBarVisible: true,
      },
    },
    personalAssessmentsRoutes,
  ],
}
