import urls from '@/utils/urls'
import http from './http'

const config = { baseURL: urls.RESULTS_API }

export default {
  getStudyGuide: (examId) => http.get(`/exams/${examId}/studyGuide`, config),
  getEnemStudyGuide: (assessmentId) => http.get(
    `/assessments/${assessmentId}/studyGuide`, config,
  ),
  existsStudyGuide: (examId) => http.head(`/exams/${examId}/studyGuide`, config),
  downloadStudyGuideQuestions: ({ title, guids, examType }) => {
    const structuresQty = 3

    const data = {
      codes: guids,
      structures: [structuresQty],
      title,
      registered: false,
      examType,
      filename: title,
    }

    const customConfig = {
      baseURL: urls.QUESTIONS_DATABASE_API,
      responseType: 'blob',
    }

    return http.post('/questions/downloads', data, customConfig)
  },
}
