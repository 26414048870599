import * as types from '../mutation-types'

export default {
  [types.SET_EXAMS](state, { exams }) {
    exams.sort((a, b) => (a.name > b.name ? 1 : -1))
    state.exams = exams
  },

  [types.SET_EXAM](state, { exam }) {
    state.exam = exam
  },

  [types.SET_EXAM_NAME](state, { name }) {
    state.exam.name = name
  },

  [types.CLEAR_EXAMS](state) {
    state.exams = []
  },

  [types.CLEAR_EXAM](state) {
    state.exam = {
      id: 0,
      name: '',
      gradeId: '',
      itemsCount: 0,
      applicationId: 0,
    }
  },
}
