import service from '@/crossIntegration/service'

class SupportLegacyPeriodService {
  constructor() {
    this.toggleLoaded = false
    this.toggleLoading = false
    this.isSupportLegacyEndDate = null
  }

  async loadSupportLegacyFeatureToggle() {
    if (this.toggleLoaded || this.toggleLoading) {
      return this.isSupportLegacyEndDate
    }

    this.toggleLoading = true

    const { data } = await service.fetchIsSupportLegacyEndDate()

    this.toggleLoaded = true
    this.toggleLoading = false

    this.isSupportLegacyEndDate = data.isEnabled

    return this.isSupportLegacyEndDate
  }

  getIsSupportLegacyEndDate() {
    return this.isSupportLegacyEndDate
  }
}

const supportLegacy = new SupportLegacyPeriodService()
export default supportLegacy
