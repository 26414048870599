import actions from './actions'
import mutations from './mutations'

const state = {
  cachedCategoriesStudyGuide: {},
  cachedStudyGuideVisibility: {},
}

export default {
  state,
  mutations,
  actions,
}
