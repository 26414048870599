import urls from '@/utils/urls'
import http from './http'

const config = { baseURL: urls.RESULTS_API }

const getPartialResults = (applicationResponseId) => http.get(`/applicationResponses/${applicationResponseId}/result`, {
  baseURL: urls.SUPERVISOR_API,
})

const getPartialItemsPerformance = (applicationResponseId) => http.get(
  `/applicationResponses/${applicationResponseId}/answerSheet`,
  { baseURL: urls.SUPERVISOR_API },
)

const getScores = (examId) => http.get(`/exams/${examId}/scores`, config)

const getAreasScoresByAssessment = (assessmentId) => http.get(`/assessments/${assessmentId}/irt-scores`, config)

const getGlobalScores = (examId) => http.get(`/exams/${examId}/global-scores`, config)

const getAreasScores = (examId) => http.get(`/exams/${examId}/irt-scores`, config)

const getAreasGlobalScores = (examId) => http.get(`/exams/${examId}/global-irt-scores`, config)

const getAreasGlobalScoresByAssessment = (assessmentId) => http.get(`/assessments/${assessmentId}/global-irt-scores`, config)

const getHitsResult = (assessmentId, examId) => http.get(`/exams/${examId}/hitsCount`, config)

const getPerformanceByCategories = (assessmentId, examId) => http.get(`/exams/${examId}/aggregatedItems`, config)

const getGlobalPerformanceByCategories = (examId) => http.get(`/exams/${examId}/globalGradesGroupedByCategory`, config)

const getPerfomanceByItems = (examId) => http.get(`/exams/${examId}/answerSheet`, config)

const getPerformanceItemsByAssessments = (assessmentId) => http.get(`/assessments/${assessmentId}/answerSheet`, config)

const getGlobalPerfomanceByItems = (examId) => http.get(`/exams/${examId}/globalGrades`, config)

const getGlobalPerformanceItemsByAssessments = (assessmentId) => http.get(`/assessments/${assessmentId}/globalGrades`, config)

const getRankings = (examId) => http.get(`/exams/${examId}/ranking`, config)

const getRankingsByAssessment = (assessmentId) => http.get(`/assessments/${assessmentId}/ranking`, config)

const getParticipation = (examId) => http.get(`/exams/${examId}/participationCount`, config)

const getParticipationByAssessment = (assessmentId) => http.get(`/assessments/${assessmentId}/participationCount`, config)

const getGlobalParticipationByAssessment = (assessmentId) => http.get(`/assessments/${assessmentId}/participationGlobalCount `, config)

const getCategoriesRanking = (examId) => http.get(`/exams/${examId}/categoryRanking`, config)

const getExamTypeHistogram = (assessmentId) => http.get(`/assessments/${assessmentId}/exam-type-histogram`, config)

const getIrtHistogram = (examId) => http.get(`/exams/${examId}/irtHistogram`, config)

const getClassicHistogram = (examId) => http.get(`/exams/${examId}/classicHistogram`, config)

const getStandardHistogram = (examId) => http.get(`/exams/${examId}/standard-histogram`, config)

const getCategoriesHistogram = (examId) => http.get(`/exams/${examId}/categoryHistogram`, config)

const getIrtHistogramByAssessment = (assessmentId) => http.get(`/assessments/${assessmentId}/histogram?scoreType=IRT`, config)

const getAveragesPerfomance = ({ assessmentId }) => {
  const customConfig = { baseURL: urls.BFF_API }

  return http.get(
    `/assessments/${assessmentId}/composed-results`,
    customConfig,
  )
}

const getAveragesExamPerfomance = ({ examId }) => {
  const customConfig = { baseURL: urls.BFF_API }

  return http.get(
    `/exams/${examId}/composed-results`,
    customConfig,
  )
}

const getResult = ({ examId, applicationResponseId }) => {
  const customConfig = { baseURL: urls.BFF_API }

  return http.get(
    `/exam/${examId}/applicationResponses/${applicationResponseId}/result`,
    customConfig,
  )
}

const getHitsQuestions = ({ assessmentId }) => {
  const customConfig = { baseURL: urls.BFF_API }

  return http.get(
    `/assessments/${assessmentId}/partial-result`,
    customConfig,
  )
}

const getOlympicSummary = ({ assessmentId }) => {
  const configBff = { baseURL: urls.BFF_API }

  return http.get(
    `/assessments/${assessmentId}/summary`,
    configBff,
  )
}

export default {
  getPartialResults,
  getPartialItemsPerformance,
  getScores,
  getAreasScoresByAssessment,
  getGlobalScores,
  getAreasGlobalScoresByAssessment,
  getAreasScores,
  getAreasGlobalScores,
  getPerformanceByCategories,
  getGlobalPerformanceByCategories,
  getHitsResult,
  getPerfomanceByItems,
  getPerformanceItemsByAssessments,
  getGlobalPerfomanceByItems,
  getGlobalPerformanceItemsByAssessments,
  getRankings,
  getRankingsByAssessment,
  getParticipation,
  getParticipationByAssessment,
  getGlobalParticipationByAssessment,
  getCategoriesRanking,
  getExamTypeHistogram,
  getIrtHistogram,
  getIrtHistogramByAssessment,
  getClassicHistogram,
  getStandardHistogram,
  getCategoriesHistogram,
  getAveragesPerfomance,
  getAveragesExamPerfomance,
  getResult,
  getHitsQuestions,
  getOlympicSummary,
}
