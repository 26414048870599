import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  questionDrawerVisible: false,
  selectedQuestion: [],
  pagedQuestions: {
    currentQuestion: 0,
    questions: [],
    markedOptions: [
      {
        itemId: 0,
        markedOption: -2,
      },
    ],
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
