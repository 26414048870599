import applicationApi from '@/api/application'
import * as types from '../mutation-types'

export default {
  getAssessmentYears: async ({ commit }) => {
    const { data } = await applicationApi.getAssessmentYears()
    commit(types.SET_ASSESSMENT_YEARS, { assessmentYears: data })

    return data
  },

  getApplications: async ({ commit }, yearFilter) => {
    const { data } = await applicationApi.getApplications(yearFilter)
    commit(types.SET_APPLICATIONS, { applications: data })

    return data
  },

  getApplication: async ({ commit }, applicationId) => {
    const { data } = await applicationApi.getApplication(applicationId)
    const application = data
    commit(types.SET_APPLICATION, { application })

    return application
  },

  getApplicationResponse: ({ commit },
    { applicationId, examId }) => applicationApi
    .getApplicationResponse({
      applicationId,
      examId,
    })
    .then((response) => {
      commit(types.SET_APPLICATION_RESPONSE, {
        applicationResponse: response.data,
      })

      return response.data
    }),
}
