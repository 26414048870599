import assessmentTypeApi from '@/api/assessmentType'
import * as types from '../mutation-types'

export default {
  getAssessmentType: ({ commit }, id) => assessmentTypeApi
    .getAssessmentType(id).then((response) => {
      commit(types.SET_ASSESSMENT_TYPE, { assessmentType: response.data })

      return response
    }),
}
