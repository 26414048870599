import api from '@/api/http'
import { setAuthCookies } from '@/utils/auth'
import urls from '@/utils/urls'

import AuthTokenServiceInstance from '@/crossIntegration/AuthTokenService'
import tokensFromCookies from '@/crossIntegration/utils/tokensFromCookies'
import { getCrossAuthToken } from '@/crossIntegration/utils/userInfo'

const config = {
  baseURL: urls.EUREKA_BASE_API,
}

async function getInitialToken() {
  let initialToken = null

  if (tokensFromCookies.sasToken) {
    initialToken = tokensFromCookies.sasToken

    AuthTokenServiceInstance.setCurrentToken(tokensFromCookies.sasToken)
  } else if (tokensFromCookies.crossToken) {
    AuthTokenServiceInstance.setCurrentToken(getCrossAuthToken({ formatted: true }))

    const { data } = await api.post('/v1/exchange-token/onb-to-sas/', { token: getCrossAuthToken() }, config)

    setAuthCookies(data.authResponse)

    AuthTokenServiceInstance.setCurrentToken(`Bearer ${data.authResponse.token}`)

    initialToken = `Bearer ${data.authResponse.token}`
  }

  return initialToken
}

export default getInitialToken
