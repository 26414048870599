const httpCodes = {
  ok: 200,
  created: 201,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  notAcceptable: 406,
  conflict: 409,
  unsuportedMediaType: 415,
  unprocessableEntity: 422,
  tooManyRequests: 429,
  internalServerError: 500,
  serviceUnavailable: 503,
  gatewayTimeout: 504,
}

export default Object.freeze(httpCodes)
