import * as types from '../mutation-types'

export default {
  [types.SET_ITEM](state, { itemOrder }) {
    state.item = state.items.find(
      (item) => parseInt(item.studentOrder, 10) === parseInt(itemOrder, 10),
    )
  },

  [types.SET_ITEMS](state, { items }) {
    state.items = items
  },

  [types.CLEAR_ITEMS](state) {
    state.items = []
  },

  [types.SET_ANSWER](state, { answer }) {
    state.item.answer = answer
  },

  [types.SET_DISCARD_OPTIONS](state, discardOption) {
    state.item.discardOption = discardOption
  },

  [types.ENABLE_ANSWER_STATUS](state) {
    state.isAnswering = true
  },

  [types.DISABLE_ANSWER_STATUS](state) {
    state.isAnswering = false
  },

  [types.CACHE_EXAM_ITEM](state, { key, examItem }) {
    state.cachedExamItem[key] = examItem
  },

  [types.CACHE_ITEM_VIDEO](state, { key, itemVideo }) {
    state.cachedItemVideo[key] = itemVideo
  },
}
