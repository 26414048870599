import * as types from '../mutation-types'

export default {
  [types.SET_ASSESSMENTS](state, { assessments }) {
    state.assessments = assessments
  },

  [types.SET_ASSESSMENT](state, { assessment }) {
    state.assessment = assessment
  },

  [types.CACHE_ASSESSMENT](state, { key, assessment }) {
    state.cachedAssessment[key] = assessment
  },
}
