import examsApi from '@/api/exam'
import * as types from '../mutation-types'

export default {
  getExams: async ({ commit }, { assessmentId, application }) => {
    try {
      const { appointments } = application
      const examsPromises = appointments.map((appointment) => examsApi.getExam({
        assessmentId,
        examId: appointment.examId,
      }))
      const responses = await Promise.all(examsPromises)
      const exams = responses.map((response) => response.data)
      const avaiableExams = exams.map((exam, index) => ({
        ...exam,
        ...appointments[index],
      }))
      commit(types.SET_EXAMS, { exams: avaiableExams })

      return null
    } catch (error) {
      return error
    }
  },

  clearExams: ({ commit }) => {
    commit(types.CLEAR_EXAMS)
  },

  getExam: ({ commit }, { assessmentId, examId }) => examsApi.getExam({
    assessmentId,
    examId,
  }).then(({ data }) => {
    commit(types.SET_EXAM, { exam: data })

    return ''
  }),

  clearExam: ({ commit }) => {
    commit(types.CLEAR_EXAM)
  },

  startExam: (context, {
    applicationId,
    examId,
    foreignLanguageCode,
  }) => examsApi.startExam({
    applicationId,
    examId,
    foreignLanguageCode,
  }),

  endExam: (context, {
    applicationResponseId,
    finalizedByStudent,
  }) => examsApi.endExam({
    applicationResponseId,
    finalizedByStudent,
  }),

  getTimeLeft: async (context, applicationResponseId) => {
    const { data } = await examsApi.getApplicationResponsesInfos(
      applicationResponseId,
    )

    return data
  },

  getForeignLanguages: async () => {
    const { data } = await examsApi.getForeignLanguages()

    return data
  },
}
