const ptBR = {
  commons: {
    year: 'Ano',
    unavailable: 'Não disponível',
    assessments: 'Avaliações',
    all: 'Todas',
    retry: 'Tentar novamente',
    darkModeEnabled: 'Modo escuro ativado | Modo escuro desativado',
    irtAverage: 'Média TRI',
    classicAverage: 'Média Bruta',
    averageGlobal: 'Média Nacional',
    questionsCount: '{amount} questão | {amount} questões',
    questionIndex: 'Questão {0}',
    lecture: 'Disciplina',
  },
  bottomSheet: {
    components: {
      order: 'Ordenar',
    },
    ordering: 'Ordenar avaliações',
    chooseOption: 'Escolha a opção',
    mostRecent: 'Mais recentes',
    alphabeticOrder: 'Ordem Alfabética',
  },
  pages: {
    error: {
      404: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 404 - Página não encontrada',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar para o início',
      },
    },
    finalResult: {
      items: {
        lectureFilter: 'Filtrar por disciplina',
        knowledgeAreaFilter: 'Filtrar por área de conhecimento',
      },
      hitsQuestions: {
        label: '{0} de {1} questões',
      },
      examCard: {
        position: '{0}º lugar',
        participants: 'de {0}',
        totalParticipants: '{0}/{1} participantes',
        average: 'média',
      },
      emptyState: {
        title: 'Ops! Não há resultados para essa prova.',
        description: 'Parece que não existem respostas suficientes para medir resultados para a prova selecionada',
      },
      error: 'Não foi possível buscar as informações dos exames',
    },
    studyGuide: {
      title: 'Um plano de estudos para você',
      description: 'Pratique exercícios nas habilidades em que mais precisa',
    },
    assessmentsExams: {
      essay: 'Redação',
      essayDetails: 'Detalhes da redação',
      viewEssayTheme: 'Visualizar tema da redação',
      available: 'Disponível',
      numberLines: 'Entre {0} e {1} linhas',
    },
  },
  exam: {
    enableDarkMode: 'Desativar modo escuro | Ativar modo escuro',
    modalReview: {
      text: 'Deseja realmente finalizar esta prova?',
      confirmText: 'Você respondeu apenas {0}/{1} questões. Realmente deseja finalizar a prova?',
      buttonCancel: 'Não, voltar',
      buttonConfirm: 'Sim, finalizar',
      newFeature: 'Novidade! Agora você pode selecionar ao lado se deseja fazer sua prova em modo escuro.',
    },
    footerPagination: {
      questionButton: 'Questões',
      previousButton: 'Anterior',
      nextButton: 'Próxima',
      reviseButton: 'Revisar e finalizar prova',
      reviseButtonMobile: 'Revisar e finalizar',
    },
    examItemOptions: {
      scratchOption: 'Não é essa',
      clearOption: 'Pode ser essa',
      selectedOption: 'Minha resposta',
    },
  },
  components: {
    averagesPerfomance: {
      title: 'Desempenho',
      footer: {
        school_ranking: 'Classificação na escola',
        studentAmount: 'Participantes',
        global_ranking: 'Classificação nacional ',
        globalStudentAmount: 'Participantes nacional',
        school_highest_score: 'Maior Nota TRI da Escola',
        school_lowest_score: 'Menor Nota TRI da Escola',
        rakingPosition: '{0}º lugar',
        studentsAmount: 'Nenhum aluno | {count} aluno | {count} alunos',
        school_student_amount: 'Participantes na escola',
        global_student_amount: 'Partcipantes nacional',
      },
      error: 'Erro ao obter dados',
    },
    questionBanner: {
      title: 'Avaliação detalhada',
      description: 'Confira as informações das questões da avaliação.',
    },
    questionDrawer: {
      error: 'Erro ao obter questões',
    },
    AssessmentQuestionsTable: {
      headers: {
        title: 'Desempenho por questão',
        question: 'Questões',
        lecture: 'Disciplina',
        answer: 'Resposta',
      },
    },
    questionDetails: {
      question: 'Questão {0}',
      enunciation: 'Enunciado',
      hit: 'Acertou',
      missed: 'Errou',
      invalid: 'Marcação inválida',
      preUni: 'Conteúdo pré-uni',
      preUniDescription: '{0} - Aula {1}',
      preVest: 'Conteúdo pré-Vest',
      preVestDescription: '{0} - Módulo {1}',
      resolution: 'Resolução',
    },
    sPaginatedTable: {
      loading: 'Carregando...',
    },
    examPartial: {
      avaliableAt: 'Seu resultado detalhado estará disponível no dia',
    },
    hitsBoard: {
      notAnswered: 'Você respondeu menos de 10% das questões da prova. Por isso, é possível que seu resultado final não seja disponibilizado.',
    },
    examResultGeneralRanked: {
      scoreNotUsed: 'A nota de {notPresent} não compõe a média e não é usada para classificação.',
    },
    partialBanner: {
      title: 'Este é o seu Resultado Parcial',
      description: 'Analise seu desempenho nas questões e saiba no que foi bem e naquilo que pode melhorar.',
      resultDate: 'Seu resultado detalhado sairá no dia <b>{date}</b>. Nele você verá notas e colocação.',
    },
    performanceCategoryCard: {
      total: '{hitsCount} de {itemsCount} questões',
    },
    categoryPerformance: {
      tooltip: 'A nota de {name} não compõe a média e não é usada para classificação.',
    },
    rankedPlacement: {
      info: 'Para o cálculo das classificações, foram utilizadas as notas da TRI.',
    },
  },
}

export default ptBR
