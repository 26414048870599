import axios from 'axios'
import { stringify } from 'qs'

import {
  updateTokens,
  hasRefreshToken,
} from '@/utils/auth'
import httpCodes from '@/enums/httpCodes'
import urls from '@/utils/urls'
import AuthTokenServiceInstance from '@/crossIntegration/AuthTokenService'
import { addInjectedAuthorizationHeader, addXAccountIdHeader } from '@/crossIntegration/utils/apiConfig'

import getIsFromCross from '@/crossIntegration/utils/getIsFromCross'
import { handleRedirection, logout } from '@/crossIntegration/utils/auth'

axios.defaults.baseURL = urls.LOTUS_API

const api = axios.create({
  baseURL: urls.LOTUS_API,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
})

api.interceptors.request.use((config) => addInjectedAuthorizationHeader(
  config,
  AuthTokenServiceInstance,
))
api.interceptors.request.use(addXAccountIdHeader)

const handleRequestInterceptorError = async (error) => {
  if (error.response) {
    const { status } = error.response

    if (status === httpCodes.unauthorized) {
      if (hasRefreshToken()) {
        try {
          await updateTokens()

          await axios(addInjectedAuthorizationHeader(error.config, AuthTokenServiceInstance))
        } catch (refreshTokenError) {
          logout({ isFromCross: getIsFromCross() })
        }
      }
    }

    const timeout = 1000
    setTimeout(() => {
      handleRedirection({
        status,
        isFromCross: getIsFromCross(),
      })
    }, timeout)
  }

  return Promise.reject(error)
}

api.interceptors.response.use(
  (response) => response,
  handleRequestInterceptorError,
)

export default api
