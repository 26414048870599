import * as types from '../mutation-types'

export default {
  [types.SET_USER](state, { user }) {
    state.user = user
  },

  [types.CACHE_USER]: (state, { key, user }) => {
    state.cachedUser[key] = user
  },

  [types.CLEAR_USER](state) {
    state.user = {
      id: 0,
      name: '',
      profiles: [{ name: '' }],
      authorization: '',
    }
  },
}
