import * as types from '../mutation-types'

export default {
  [types.SET_ASSESSMENT_YEARS](state, { assessmentYears }) {
    state.assessmentYears = assessmentYears
  },
  [types.SET_APPLICATIONS](state, { applications }) {
    state.applications = applications
  },

  [types.SET_APPLICATION](state, { application }) {
    state.application = application
  },

  [types.CACHE_APPLICATION](state, { key, application }) {
    state.cachedApplication[key] = application
  },

  [types.SET_APPLICATION_RESPONSE](state, { applicationResponse }) {
    state.applicationResponse = applicationResponse
  },
}
