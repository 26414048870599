import services from '../services'
import * as types from './types'

export default {
  fetchLectures: async ({ state, commit }) => {
    if (state.cachedLectures.length) {
      return state.cachedLectures
    }

    const { data } = await services.fetchLectures()

    commit(types.CACHE_LECTURES, { lectures: data })

    return data
  },
}
