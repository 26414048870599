
import * as types from '../mutation-types'

export default {
  setDarkMode: ({ commit }, enable) => {
    commit(types.SET_DARK_MODE, enable)
    localStorage.setItem('darkMode', enable)
  },

  getDarkMode: ({ dispatch }) => {
    const darkModeEnabled = JSON.parse(localStorage.getItem('darkMode'))

    if (darkModeEnabled) {
      dispatch('setDarkMode', darkModeEnabled)
    }
  },
}
