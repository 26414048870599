import { getCrossAccountId } from './userInfo'

export const addInjectedAuthorizationHeader = (config, tokenInstance) => {
  const requestConfig = { ...config }
  const token = tokenInstance.getCurrentToken()

  requestConfig.headers.Authorization = token

  return requestConfig
}

export function addXAccountIdHeader(config, accountId = getCrossAccountId()) {
  const requestConfig = { ...config }

  requestConfig.headers['x-account-id'] = accountId

  return requestConfig
}

export default {
  addInjectedAuthorizationHeader,
  addXAccountIdHeader,
}
